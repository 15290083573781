import React, { useEffect, useRef } from 'react';

const Inview = ({children, c_name, tag}) =>{
    const invew_target = useRef(null);

    useEffect(()=>{
      const cb = (entries) => {
        entries.forEach((entry)=>{
          if(entry.isIntersecting){
            //クラスの付け替え
            invew_target.current.classList.add("inview")
          }
        })
      };
      const options = {
        root:null,
        rootMargin: "0px 0px 0px 0px",
      }
      const io = new IntersectionObserver(cb, options);
      io.observe(invew_target.current)
    },[])

    if(tag === "p"){
        return (
          <>
            <p className={c_name} ref={invew_target}>{children}</p>
          </>
        )
    }
    else if(tag === "span"){
        return (
          <>
            <span className={c_name} ref={invew_target}>{children}</span>
          </>
        )
    }
    else if(tag === "h1"){
        return (
          <>
            <h1 className={c_name} ref={invew_target}>{children}</h1>
          </>
        )
    }
    else if(tag === "h2"){
      return (
        <>
          <h2 className={c_name} ref={invew_target}>{children}</h2>
        </>
      )
  }
    else{
        return (
            <>
              <div className={c_name} ref={invew_target}>{children}</div>
            </>
          )   
    }
  }
  
  export default Inview;